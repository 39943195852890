@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

.ql-container { 
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif !important;
  font-size:15px !important;
}

.ql-container p {
  font-size:15px !important;
}

.category-highlight::after,
.tag-highlight::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.3em; /* 형광펜 높이 조절 */
  background-color: #ffeb3b; /* 형광펜 색상 */
  z-index: -1; /* 텍스트 위에 효과가 오도록 설정 */
}

.category-highlight {
  display: inline-block;
  padding-bottom: 2px; /* 텍스트 아래 패딩 추가 */
  position: relative;
}

.tag-highlight {
  display: inline-block;
  padding-bottom: 2px;
  position: relative;
}
